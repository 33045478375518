
// eslint-disable-next-line import/extensions
import anime from 'animejs/lib/anime.es.js';
import { defineComponent, reactive, toRefs } from 'vue';
import ProjectList from '@/components/ProjectList.vue';
import NameTypographyStrokeSvg from '../components/NameTypographyStrokeSvg.vue';

export default defineComponent({
  name: 'HomeView',
  setup() {
    const state = reactive({
      landingSubheadingText: 'Full Stack Web Developer' as string,
      aboutTitle: 'About' as string,
      aboutSubheadingText: [
        'I\'m a career-driven and personable individual always looking to develop my portfolio of skills and experience.',
        `I have a bachelor's degree in Computer Science which has afforded me a solid understanding of computer theory; building upon
        this is my years working in the industry which has further developed my understanding into practical skills and knowledge.`,
      ] as string[],
      projectsTitle: 'Projects' as string,
      upTargetOffset: 0 as number,
      downTargetOffset: 0 as number,
      scrollDownCount: 0 as number,
    });
    return { ...toRefs(state) };
  },
  mounted() {
    this.setupScrollButtons();
    anime({
      targets: '#stroke-svg path',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 1500,
      delay(e: HTMLElement, i: number) { return i * 300; },
      direction: 'alternate',
      loop: false,
    });
  },
  methods: {
    setupScrollButtons() {
      this.downTargetOffset = document.getElementById('about-section')?.offsetHeight as number;

      document.addEventListener('scroll', () => {
        const { scrollTop } = document.documentElement;
        const aboutSection = document.getElementById('about-section') as HTMLElement;
        const projectsSection = document.getElementById('projects-section') as HTMLElement;

        // Scrolled past the landing section
        if (scrollTop >= aboutSection.offsetTop) {
          document.getElementById('scroll-button-up')?.classList.remove('hide');
          this.upTargetOffset = 0;
          this.downTargetOffset = projectsSection.offsetTop;
        } else {
          // bug here
          document.getElementById('scroll-button-up')?.classList.add('hide');
          this.downTargetOffset = aboutSection.offsetTop;
        }

        // Scrolled past the about section
        if (scrollTop >= projectsSection.offsetTop) {
          document.getElementById('scroll-button-down')?.classList.add('hide');
          this.upTargetOffset = aboutSection.offsetTop;
          // bug here
        } else {
          document.getElementById('scroll-button-down')?.classList.remove('hide');
        }

        if (this.scrollDownCount === 2) {
          this.upTargetOffset = document.getElementById('about-section')?.offsetHeight as number;
        }
      });

      const scrollButtonUp = document.getElementById('scroll-button-up') as HTMLElement;
      const scrollButtonDown = document.getElementById('scroll-button-down') as HTMLElement;

      scrollButtonUp.addEventListener('click', () => {
        this.scrollDownCount -= 1;
        window.scrollTo({
          top: this.upTargetOffset,
          behavior: 'smooth',
        });
        if (this.scrollDownCount < 2) {
          document.getElementById('scroll-button-down')?.classList.remove('hide-scroll-button');
        }
      });

      scrollButtonDown.addEventListener('click', () => {
        this.scrollDownCount += 1;
        window.scrollTo({
          top: this.downTargetOffset,
          behavior: 'smooth',
        });
        if (this.scrollDownCount === 2) {
          this.upTargetOffset = document.getElementById('about-section')?.offsetHeight as number;
          document.getElementById('scroll-button-down')?.classList.add('hide-scroll-button');
        }
      });
    },
  },
  components: {
    ProjectList,
    NameTypographyStrokeSvg,
  },
});

<!-- eslint-disable max-len -->
<template>
<svg id="stroke-svg" width="100%" viewBox="0 0 326 117" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M52.6888 4C39.0077 10.7 31.0353 12.3 22.866 10C20.8975 9.4 18.5353 8.1 17.5511 7C16.5668 5.9 15.3857 5 14.8936 5C12.1377 5 18.0432 18.3 22.2755 21.4C25.8188 24 35.366 24.6 42.6495 22.6C45.6022 21.8 48.1613 21.3 48.2597 21.4C48.4566 21.5 46.2912 26.8 43.5353 33.1C40.7794 39.3 37.2361 48.1 35.5629 52.5C33.9881 56.9 32.118 61.9 31.3306 63.5C30.6416 65.2 28.181 71.2 26.0156 76.9C19.5196 93.7 11.5471 107 8.88964 105.3C8.29909 104.9 8.10224 99.5 8.49594 90.8C8.98807 78.5 8.79121 76.7 7.21641 74.8C4.55893 71.5 2.78728 73.7 1.50775 81.8C0.32665 89.5 1.3109 103 3.37783 105.7C6.72429 110.3 13.7125 117 15.0904 117C17.3542 117 22.2755 111.5 25.7203 105C29.0668 98.8 34.8739 84.5 41.1731 66.5C48.0629 46.8 51.114 39.7 58.4959 25.9C65.0904 13.4 65.3857 12.5 64.7951 8.1C64.1062 3.2 62.7282 -1.74344e-06 61.5471 -1.74344e-06C61.055 -1.74344e-06 57.118 1.8 52.6888 4Z" stroke="black"/>
<path d="M208.615 4.37469C200.025 13.7994 184.918 41.8731 177.71 61.9258C173.958 72.1526 168.231 94.3108 167.343 102.232C166.651 107.846 166.651 107.846 170.798 112.458C176.427 118.675 178.599 118.575 185.017 111.757C190.645 105.841 202 89.2977 201.901 86.9916C201.901 85.4876 198.741 81.9784 197.458 81.9784C196.174 81.9784 191.928 86.5905 184.721 95.7145C180.771 100.828 177.118 105.039 176.624 105.039C176.13 105.039 175.933 103.836 176.328 102.332C181.166 80.4745 186.103 65.2345 193.311 49.6937C198.346 38.765 207.924 22.5223 211.874 18.0105L214.243 15.3034L211.676 22.3218C205.752 38.2637 205.752 38.2637 208.319 41.7729C209.504 43.5776 210.985 44.7808 211.577 44.5802C213.848 43.8784 216.416 38.8652 220.168 28.0368C225.104 13.8997 225.203 10.3905 220.859 4.57522C216.317 -1.44058 214.046 -1.54084 208.615 4.37469Z" stroke="black"/>
<path d="M126.305 11.1159C126.108 11.8201 125.222 15.8437 124.237 19.9679C123.252 24.0921 120.987 32.9441 119.115 39.5831C117.244 46.222 115.176 53.8669 114.388 56.6835C113.698 59.3994 111.925 65.9378 110.547 71.0679C107.69 81.5293 107.198 86.2571 109.266 84.6476C109.857 84.0441 111.236 83.7423 112.122 83.8429C113.403 84.0441 114.289 82.5352 115.964 77.5057C117.146 73.7838 118.426 70.3638 118.918 69.8608C119.411 69.2573 119.805 67.7484 119.805 66.3401C119.805 61.4112 121.381 60.7071 123.055 64.9319C125.123 70.3638 133.889 85.6535 135.071 85.9553C135.662 86.1565 136.844 85.6535 137.829 84.9494C139.503 83.6417 139.503 83.2394 137.533 77.2039C136.351 73.6833 133.889 67.849 132.018 64.2277C130.147 60.7071 128.669 57.3876 128.669 56.9852C128.669 56.5829 131.033 54.4705 133.988 52.2575C141.473 46.5238 145.61 38.9795 141.178 39.0801C140.488 39.0801 136.942 41.0919 133.101 43.6067C129.359 46.0209 126.207 47.9321 126.108 47.8315C126.01 47.7309 127.389 42.299 129.162 35.8612C130.934 29.4234 132.707 21.6779 133.101 18.6602C133.692 13.6307 133.594 13.1278 131.23 11.6189C128.275 9.60708 126.798 9.50649 126.305 11.1159Z" stroke="black"/>
<path d="M260.947 14.0198C260.059 19.3057 250.29 53.9134 242.889 77.4506C242.198 79.8442 241.803 82.3376 242.099 83.1354C242.79 84.7312 245.356 84.0331 247.231 81.7392C248.218 80.3429 248.316 80.4426 247.921 82.1381C247.625 83.2352 248.218 82.3376 249.303 80.1434C250.388 77.9493 251.277 75.5557 251.277 74.7578C251.375 74.0597 252.263 71.7658 253.25 69.7711C256.408 63.6873 267.361 27.0849 268.644 18.5078C269.335 14.2193 269.236 13.9201 266.374 12.424C262.131 10.2299 261.539 10.4294 260.947 14.0198Z" stroke="black"/>
<path d="M278.267 13.6288C277.962 15.2409 277.25 17.5582 276.843 18.9688C276.334 20.3794 273.79 28.9435 271.348 38.1123C268.804 47.281 264.836 61.1852 262.496 69.1448C259.342 79.4218 258.528 83.8551 259.24 84.5603C260.054 85.3664 260.97 85.0641 262.394 83.6535C263.819 82.243 264.633 81.9407 264.938 82.7468C265.141 83.452 266.159 81.4369 267.075 78.4143C268.092 75.3916 269.72 71.0592 270.738 68.8426C275.215 58.8678 286 22.596 286 17.4575C286 15.0394 285.288 13.528 283.762 12.5205C280.506 10.2031 278.979 10.5054 278.267 13.6288Z" stroke="black"/>
<path d="M66.8324 45.0082C55.237 52.9245 46 65.7508 46 73.9676C46 77.074 46.6879 78.8777 49.0462 81.5832C53.6648 86.8941 55.3353 87.5956 59.2659 85.6917C61.133 84.7898 63.4914 82.8859 64.474 81.5832C65.5549 80.1804 66.6359 79.0781 66.9307 79.0781C67.1272 79.0781 67.6185 81.7836 67.9133 85.0904C68.4047 91.203 70.9596 97.1151 73.4162 97.9167C74.3006 98.2173 74.4972 97.7163 74.0058 96.2132C71.7457 88.9984 74.4972 73.8674 80.5896 59.6382C82.2602 55.63 83.2428 51.9224 82.948 50.6197C82.2602 47.7138 76.1677 41 74.3006 41C73.4162 41 70.0752 42.8037 66.8324 45.0082ZM73.9076 53.5257C72.5318 57.4337 70.37 62.3438 69.0925 64.5483C66.1445 69.759 58.0867 77.6752 54.4509 79.0781L51.6012 80.1804L52.0925 75.7713C52.5838 71.2621 55.8266 65.6506 61.7226 59.037C63.4914 57.1331 65.5549 54.6279 66.5376 53.4255C67.9133 51.6218 75.6763 46.0103 76.1677 46.4111C76.2659 46.5113 75.2833 49.7179 73.9076 53.5257Z" stroke="black"/>
<path d="M99.646 45.006C95.3633 48.6132 86.798 60.2365 82.7145 67.8517C79.0294 74.8658 79.129 78.974 82.8141 82.982C86.2004 86.6894 89.2879 86.8898 94.8653 84.0842C100.144 81.3788 101.339 78.974 99.0484 76.0682L97.2556 73.7636L92.3754 75.968C89.7859 77.1704 87.3956 77.8718 87.1964 77.6714C86.4992 76.97 90.8815 68.9539 95.3633 62.5411C100.244 55.7275 104.028 51.9199 108.112 49.8156L111 48.4128L108.311 45.2064C106.817 43.4028 105.124 42 104.427 42C103.829 42 101.638 43.4028 99.646 45.006Z" stroke="black"/>
<path d="M223.212 44.3C221.457 45.5 218.728 48.6 217.363 51C214.244 56.2 212.782 60.6 214.439 59.6C215.024 59.2 214.634 60.4 213.659 62.2C210.248 68.1 209.175 73.5 210.637 77.1C212.1 80.5 218.63 86 221.457 86C223.407 86 231.594 79.9 231.594 78.4C231.594 77 238.125 68 239.1 68C240.465 68 242.902 61.1 242.999 56.9C243.096 49.7 236.176 42 229.547 42C227.89 42 225.064 43 223.212 44.3ZM236.761 49.2C237.443 49.9 237.93 51.3 237.93 52.4C237.93 55.6 232.764 66 228.67 71C225.454 75 219.118 80 217.266 80C214.634 80 218.923 68.1 223.602 62.4C225.064 60.6 226.233 58.8 226.233 58.3C226.233 57.8 227.598 56.1 229.255 54.4C231.01 52.7 231.984 50.8 231.692 49.8C231.4 48.5 231.887 48 233.446 48C234.616 48 236.078 48.5 236.761 49.2Z" stroke="black"/>
<path d="M288.026 42.5398C287.64 42.8416 287.35 44.0486 287.35 45.155C287.35 46.362 284.938 55.5151 281.947 65.674C278.955 75.833 276.736 84.3826 277.025 84.7849C277.315 85.0867 278.666 85.0867 280.017 84.6843C281.754 84.282 282.815 82.7732 284.07 79.3534C284.938 76.6376 286.096 74.1231 286.578 73.5196C286.964 73.0166 287.35 71.9102 287.35 70.9044C287.35 69.9991 288.798 66.3781 290.535 62.8577C294.491 54.7104 297 48.2731 297 46.362C297 43.8474 289.666 40.7293 288.026 42.5398Z" stroke="black"/>
<path d="M304.3 43.9266C300.5 46.2306 299.5 49.2358 300.5 55.5468C301.3 61.357 304 64.1619 312.3 67.9685C315.7 69.5713 317.9 70.8736 317.2 70.9738C316.4 70.9738 314.4 71.5748 312.7 72.3762C310.9 73.1776 307 74.5801 304 75.5818C296.6 77.8858 294.1 79.8893 294 83.1951C294 84.7979 294.5 86 295 86C298.1 86 321.4 79.4886 322.6 78.2865C324.4 76.4834 324.5 66.9668 322.7 64.4624C321.9 63.4607 317.6 60.8561 313 58.5521C308.4 56.2481 304.5 53.944 304.3 53.2428C303.3 50.3378 316.3 49.1357 323.8 51.4397C325.6 52.0407 326 51.7402 326 50.2376C326 47.4327 323 45.0285 317.4 43.3255C311.1 41.4222 308.1 41.5224 304.3 43.9266Z" stroke="black"/>
</svg>

</template>

<style scoped>
#stroke-svg {
  max-width: 600px;
}
</style>


import { defineComponent, reactive, toRefs } from 'vue';

export default defineComponent({
  name: 'ProjectList',
  setup() {
    // eslint-disable-next-line no-shadow
    enum ProjectStatus {
      Ongoing = 'Ongoing',
      Inactive = 'Inactive',
      Finished = 'Finished',
    }

    interface Project {
      name: string;
      description: string[];
      image: string;
      githubLink: string;
      demoLink: string;
      pageLink: string;
      status: ProjectStatus;
      tags: string[];
    }

    const state = reactive({
      projects: [
        {
          name: 'Algo Sandbox',
          description: [
            'A visual sandbox to test path finding algorithms and more...',
          ],
          image: 'algo-sandbox.png',
          githubLink: 'https://github.com/collisj1996/algo-sandbox',
          demoLink: 'https://collisj1996.github.io/algo-sandbox/',
          pageLink: '',
          status: ProjectStatus.Ongoing,
          tags: ['JavaScript', 'TypeScript', 'Vue 3', 'Composition API', 'Alogrithms', 'Pathfinding', 'Visualization'],
        },
        {
          name: 'Portfolio',
          description: [
            'High quality, responsive, and user-friendly portfolio website.',
          ],
          image: '',
          githubLink: '',
          demoLink: '',
          pageLink: '',
          status: ProjectStatus.Ongoing,
          tags: ['JavaScript', 'TypeScript', 'Vue 3', 'Composition API', 'UI', 'UX', 'Responsive'],
        },
        {
          name: 'Minecraft Server Hub',
          description: [
            'A server hub for users to browse, upload, and vote on their favourite servers.',
            `As my biggest project, this was an end-to-end solution, including a VUE2 frontend,
            a serverless NodeJS backend using AWS Lambda, and a MySQL database hosted on AWS RDS.`,
            `Whilst the project is no longer active due to heavy running costs, I do one day want to
            pick up this project again.`],
          image: 'msh.png',
          githubLink: 'https://github.com/collisj1996/minecraft-server-hub-frontend',
          demoLink: 'https://minecraftserverhub.com/',
          pageLink: '',
          status: ProjectStatus.Finished,
          tags: ['JavaScript', 'Vue 2', 'Vuex', 'Full Stack', 'Serverless', 'AWS', 'MySQL', 'SQS', 'EC2', 'NodeJS', 'Lambda', 'RDS'],
        },
        {
          name: 'Game of Life',
          description: ['A recreation of Conway\'s Game of Life in Javscript.'],
          image: 'game-of-life.png',
          githubLink: 'https://github.com/collisj1996/Game-of-Life',
          demoLink: 'https://collisj1996.github.io/Game-of-Life/',
          pageLink: '',
          status: ProjectStatus.Finished,
          tags: ['JavaScript', 'Algorithms', 'MVC', 'Visualization'],
        },
        {
          name: 'Raycasting',
          description: ['A visualisation of a simple 2D "raycasting" algorithm.'],
          image: 'raycasting.png',
          githubLink: 'https://github.com/collisj1996/Raycasting',
          demoLink: 'https://collisj1996.github.io/Raycasting/',
          pageLink: '',
          status: ProjectStatus.Finished,
          tags: ['JavaScript', 'Algorithms', 'Interactive', 'Visualization'],
        },
        {
          name: 'Memory Jar',
          description: ['An android based mobile application developed to help people with dementia to remember things, based on the premise of positive reminiscence.'],
          image: 'memory-jar.png',
          githubLink: 'https://github.com/collisj1996/MemoryJar',
          demoLink: '',
          pageLink: '',
          status: ProjectStatus.Finished,
          tags: ['Java', 'Android', 'Mobile'],
        },
        {
          name: 'Conversion Toolkit',
          description: [
            'The Conversion Toolkit mobile application for Android provides users with an easy-to-use "toolkit" for day to day conversions. Whether you are trying to convert Kilometers to Miles, understand the amount of Electronvolts in a Joule or see how many Indian Rupees you can get for 50 British Pounds, Conversion Toolkit can do it all.',
          ],
          image: 'conversion-toolkit.png',
          githubLink: 'https://github.com/collisj1996/Unit-Conversion-Tool',
          demoLink: 'https://play.google.com/store/apps/details?id=com.arcticumi.Conversion_Toolkit&hl=en_US',
          pageLink: '',
          status: ProjectStatus.Finished,
          tags: ['Java', 'Android', 'Mobile'],
        },
      ] as Project[],
    });

    return { ...toRefs(state) };
  },
  methods: {
    getImage(imageUrl: string) {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      return require(`@/assets/${imageUrl}`);
    },
    getStatusClass(status: string) {
      switch (status) {
        case 'Ongoing':
          return 'status-ongoing';
        case 'Inactive':
          return 'status-inactive';
        case 'Finished':
          return 'status-finished';
        default:
          return '';
      }
    },
  },
});
